import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class EditOrCreateValidator extends ValidationMixin {
    private codeRules = [
        (v: string | any) => this.required(v) || 'Le champ code est obligatoire',
    ];

    private secteurRules = [
        (v: string | any) => this.required(v) || 'Le champ secteur est obligatoire',
    ];

    private libelleRules = [
        (v: string | any) => this.required(v) || 'Le champ libellé est obligatoire',
    ];

    private arreteRules = [
        (v: string | any) => this.required(v) || 'Le champ arrêté est obligatoire',
    ];

    private familleRules = [
        (v: string | any) => this.required(v) || 'Le champ famille est obligatoire',
    ];

    private objectTravauxFinRules = [
        (v: string | any) => this.required(v) || 'Le champ objet travaux est obligatoire',
    ];

    private dateDebutRules = [
        (v: string | any) => this.required(v) || 'Le champ date de début est obligatoire',
    ];

    private dateFinRules = [
        (v: string | any) => this.required(v) || 'Le champ date de fin est obligatoire',
    ];

    private dateEntreeApplicationControleRules = [
        (v: string | any) => this.required(v) || 'La date d\'entrée en application du contrôle est obligatoire',
    ];

    private denominationRules = [
        (v: string | any) => this.required(v) || 'Le champ dénomination est obligatoire',
    ];

    private conditionsDelivrancesRules = [
        (v: string | any) => this.required(v) || 'Le champ conditions de délivrance est obligatoire',
    ];

    private cheminAHRules = [
        (v: string | any) => this.required(v) || 'Le chemin du template d\'AH est obligatoire',
    ];
}
