







































































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EditOrCreateValidator from './EditOrCreate.Validator';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { TemplateOperation, SecteurReferentiel, ArreteReferentiel } from '@/models';
import { DateHelper } from '@/shared/helpers';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { isNullOrUndefined, isNullOrEmpty } from '@/shared/helpers';
import { Profils } from '@/shared/enums';
import { cloneDeep, omit } from 'lodash-es'

@Component({
    name: 'EditOrCreate',
    components: {
        ValidationProvider,
        ValidationObserver,
        CeeDatePicker,
        // Use the <ckeditor> component in this view.
        ckeditor: CKEditor.component,
    },
})
export default class EditOrCreate extends Mixins(EditOrCreateValidator) {
    public $refs!: {
        form: HTMLFormElement,
    };
    /**
     * Pour utiliser v-model au niveau du composant en définissant la propriété 'value' et mise à jour avec l'événement 'input'
     * Voir https://fr.vuejs.org/v2/guide/components.html#Personnalisation-de-composant-avec-v-model
     * */
    @Prop({ default: new TemplateOperation() })
    public value: TemplateOperation;

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    public editor: any = ClassicEditor;
    public editorConfig: { [key: string]: any } = {
        toolbar: [
            'Bold', 'Italic', 'Link',
            '|', 'BulletedList', 'NumberedList', 'BlockQuote',
            '|', 'Undo', 'Redo'],

        // The configuration of the editor.
        fillEmptyBlocks: false,
        basicEntities: false,
        entities: false,
        entities_greek: false,
        entities_latin: false,
        entities_additional: "",
        language: "fr",
    }
    // Affiche ou pas le loader.
    public loading: boolean = false;
    //
    public get templateOperation(): TemplateOperation {
        return this.value ? this.value : new TemplateOperation();
    }

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Secteur))
    public secteurs: SecteurReferentiel[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Arrete))
    public arretes: ArreteReferentiel[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.AttestationHonneur))
    public cheminsAH: ValeurReferentielle[];

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeDocument))
    public allTypeDocuments: ValeurReferentielle[];

    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;
    /**
     * Retourne les chemins d'AH filtrés en fonction du code saisi sinon tous.
     */
    public get filteredCheminAH(): ValeurReferentielle[] {
        if (this.templateOperation && !isNullOrUndefined(this.templateOperation.code) || !isNullOrEmpty(this.templateOperation.code) && this.cheminsAH) {
            const comparateur = this.templateOperation.code.substring(0, 3).toUpperCase();
            if (comparateur) {
                return this.cheminsAH.filter((itemChemin) => itemChemin.libelle.includes(comparateur));
            }
        }
        return this.cheminsAH;
    }

    public mounted(): void {
        this.getValeursReferentielles(TypeValeurReferentielle.Secteur);
        this.getValeursReferentielles(TypeValeurReferentielle.Arrete);
        this.getValeursReferentielles(TypeValeurReferentielle.AttestationHonneur);
        this.getValeursReferentielles(TypeValeurReferentielle.TypeDocument);
        
    }
    /**
     * Retourne l'URL de création ou modification.
     */
    public get getUrlSave(): string {
        if (this.templateOperation) {
            if (this.templateOperation.id >= 1) {
                return `/templateOperations/${this.templateOperation.id}/edit`;

            } else {
                return `/templateOperations/create`;
            }
        }
        else {
            throw new Error("L'objet templateOperation n'est pas initialisé.")
        }
    }
    /**
     * Faire un submit.
     */
    private submit(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            const postParamsTemplateOperation = {
                ...cloneDeep(omit(this.templateOperation, 'typeDocumentTemplateOperations','selectedTypeDocumentIds')),
                ... {
                    typeDocumentTemplateOperations: (this.templateOperation.selectedTypeDocumentIds || []).map((item) => {
                        return {
                            templateOperationId: this.templateOperation.id,
                            typeDocumentId: item,
                        };
                    }) as Array<{ id: number, templateOperationId: number, typeDocumentId: number }>,
                },
                ...{
                    dateEngagementDebut: DateHelper.toIsoString(this.templateOperation.dateEngagementDebut) || this.templateOperation.dateEngagementDebut,
                    dateEngagementFin: DateHelper.toIsoString(this.templateOperation.dateEngagementFin) || this.templateOperation.dateEngagementFin,
                    dateAchevementFin: DateHelper.toIsoString(this.templateOperation.dateAchevementFin) || this.templateOperation.dateAchevementFin,
                },
            };

            this.$http.post(this.getUrlSave, postParamsTemplateOperation)
                .then((response: any) => {
                    if (response.data && !(response.data.isError && response.data.messages.length >= 1) ) {
                        (this.$router as any).push({ name: 'gestion-template-operations' });
                    }
                })
                .finally(() => this.loading = false);
        }
    }
    /**
     * Revenir en arrière.
     */
    private clear(): void {
        this.$refs.form.reset();
        this.loading = false;
    }
}
